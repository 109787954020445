(function(funk) {

	funk.debounce = function(delay) {
		var outter = this,
			timer;

		return function() {

			var inner = this,
				args = [].slice.apply(arguments);

			clearTimeout(timer);
			timer = setTimeout(function() {
				outter.apply(inner, args);
			}, delay);

		};
	};

	funk.throttle = function(delay) {
		var outter = this,
			t = 0;

		return function() {
			var inner = this,
				args = [].slice.apply(arguments);

			if (Date.now() - t < delay)
				return;

			t = Date.now();
			outter.apply(inner, args);

		};

	};
}(Function.prototype));

(function(a){

	if (typeof a.from === 'function') return;

	a.from = function(arraylike) {
		return [].slice.call(arraylike);
	};

}(Array));

function videoClick() {
	var iframe = document.createElement('iframe');
	var embed = 'https://www.youtube.com/embed/%ID%?autoplay=1';
	this.removeEventListener('click', videoClick);
	iframe.setAttribute('src', embed.replace('%ID%', this.dataset.id));
	iframe.setAttribute('frameborder', 0);
	iframe.setAttribute('allowfullscreen', 1);
	iframe.setAttribute('width', '100%');
	iframe.setAttribute('height', '100%');
	this.innerHTML = iframe.outerHTML;
}

function onDomReady() {
	const videos = Array.from( document.querySelectorAll('.yt-video') );


	if ('ontouchstart' in window) {
		document.documentElement.className = '';
	}
	else {
		document.documentElement.className = 'no-touch';
	}

	videos.map((v) => {
		const div = v.parentNode;
		div.setAttribute('data-id', v.dataset.id);
		div.setAttribute('style', `background-image: url(https://i.ytimg.com/vi/${v.dataset.id}/maxresdefault.jpg)`);
		div.innerHTML = '<div class="play"></div>';
		div.addEventListener('click', videoClick);
	});

	makeCanvas();

	window.addEventListener('load', function() {
		document.documentElement.className += ' loaded';
	});
}



function makeCanvas() {
	const canvas = document.createElement('canvas');
	const picker = document.createElement('div');
	const capa = document.querySelector('.capa');
	const colors = [
		'#d794c4',
		'#8ec4d4',
		'#dac600',
		'#a51c18',
		'#d7800f',
		'#f5d9e8',
		'#291e1a',
		'#fff'
	];

	canvas.style.cssText = 'position: absolute; width: 100%; height: 100%; top: 0; left: 0;cursor: crosshair;';

	picker.id = 'picker';

	capa.appendChild(canvas);
	capa.appendChild(picker);

	let sketch = new Sketchpad({
		element: canvas,
		width: window.innerWidth,
		height: window.innerHeight
	});

	sketch.penSize = 20 + Math.random() * 10;
	sketch.color = colors[Math.floor(Math.random() * colors.length)];

	colors.map((color) => {
		const el = document.createElement('i');
		el.style.backgroundColor = color;
		picker.appendChild(el);
		if (color === sketch.color) el.className = 'current';
	});

	picker.addEventListener('click', (e) => {
		if (e.target.nodeName !== 'I') return;
		sketch.color = e.target.style.backgroundColor;
		Array.from(picker.children).map( el => el.className = '' );
		e.target.className = 'current';
	});



	function onResize() {

		sketch.width = window.innerWidth;
		sketch.height = window.innerHeight;
		sketch.reset();

	}


	window.addEventListener('resize', onResize.throttle(100));


}

document.addEventListener('DOMContentLoaded', onDomReady);
